// Starting up Alpine 
import Alpine from "alpinejs";
import Clipboard from "@ryangjchandler/alpine-clipboard"
import focus from '@alpinejs/focus'
 
Alpine.plugin(focus)
Alpine.plugin(Clipboard);

window.Alpine = Alpine;

Alpine.start();